<template>
  <div v-if="!preload">
    <SectionBanner titulo='El único préstamo con <br class="d-none d-lg-block"/> tu auto como garantía'
                   subtitulo="Recibe el dinero en 1 día"
                   :listado="['Sin vender tu auto', 'Sin dejar de manejarlo', 'Ejecutivo personalizado']"
                   container="banner-container" />
    <SectionForm />
    <!-- Por decisión de diseño se eliminar el secion 1 y se mantiene por si queremos regresar al formulario anterior -->
    <!-- <div> <Section1 /></div> -->
    <!--#NF por decision del nuevo diseño se han invertido el orden de las dos secciones consecuentes-->
    <div>
      <Section2 />
    </div>
    <div class='mb-5 election'>
      <div class="ibancar-election">
        <p>¿Por qué elegir Ibancar?</p>
      </div>
      <BeneficiosIbancarCarousel />
    </div>
    <div>
      <Section5S1 />
    </div>
    <div class='mb-0'>
      <Section9 />
    </div>
  </div>



  <div v-else>
    <LoaderMexico />
  </div>
</template>

<script>
// @ is an alias to /src
import Section2 from '@/components/Home/Section2.vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import { useRouter } from 'vue-router'
import SectionForm from '@/components/Home/SectionForm.vue'
import LoaderMexico from '@/components/FormularioMexico/Loading.vue'
import store from "@/store";
import { mapState } from "vuex";
import SectionBanner from '../components/FormularioMexico/Sections/SectionBanner.vue'
import BeneficiosIbancarCarousel from '../components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue'
import Section5S1 from '@/components/Home/Section5S1.vue'
import Section9 from '@/components/Home/Section9.vue'
// @ is an alias to /src
export default {
  name: 'Home',
  store,
  computed: mapState({
    preload: (state) => state.preload,
  }),
  components: {
    SectionBanner,
    Section2,
    Section5S1,
    Section9,
    SectionForm,
    LoaderMexico,
    BeneficiosIbancarCarousel
  },
  mounted: function () {
    store.commit('setPreload', false)
  },
  setup() {

    useMeta({
      title: 'Préstamo con tu auto como garantía',
      meta: [
        { name: 'robots', content: 'index' },
        { name: 'facebook-domain-verification', content: 'wlcvgkjil721m5z7fv01hbicq8dwue' },
        { name: 'description', content: 'Ibancar México. Préstamo con aval de auto sin cambio de titularidad. Consigue dinero hasta $120,000 en 48h.  Evita el empeño tradicional y consigue dinero rápido mediante un préstamo personal con Ibancar. ' },
        { name: 'keywords', content: 'Préstamos por tu auto, empeña tu auto sin dejarlo, préstamos por auto, préstamos sin importar buro de credito, préstamos personales, prestamos inmediatos, credito aval auto' }
      ],
      link: [
        { href: 'https://ibancar.com', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx', rel: 'alternate', hreflang: 'es-MX' }
      ]
    });

    const router = useRouter()
    const datos_afiliado = router.currentRoute.value.query;
    if (Object.keys(datos_afiliado).length !== 0) {
      logAfiliadosWeb(datos_afiliado)
    }

    function logAfiliadosWeb(datos_afiliado) {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + 'log/afiliado/web', datos_afiliado)
        .then(
          (resp) => console.log(resp)
        ).catch(error => console.log(error))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.banner-container {
  margin-top: 1.3rem;
}

@include media-breakpoint-up(lg) {

  .banner-container {
    margin-top: 8rem;
  }

  .election {
    margin-top: 3rem;
  }

}

.ibancar-election {
  margin: 4rem 1.4rem;

  p {
    color: #004680;
    text-align: center;

    /* Mobile 360 px/H2 */
    font-family: 'Montserrat-Bold';
    font-size: 31px;
    font-style: normal;
    line-height: normal;
  }
}

@include media-breakpoint-down(md) {
  .banner-container {
    margin-top: 50px;
  }

  .ibancar-election {
    margin: 0rem 1.4rem;

    p {
      font-size: 31px
    }

    ;
  }
}

@include media-breakpoint-down(sm) {
  .ibancar-election {
    margin: 0rem 1rem;

    p {
      font-size: 20px
    }

    ;
  }
}

@include media-breakpoint-between(md, lg) {
  .banner-container {
    margin-top: 120px;
    width: 100%;
    padding-left: 4rem;
  }
}

@include media-breakpoint-only(md) {
  .banner-container {
    padding-left: 3rem;
  }
}


@media (max-width: 576px) {
  .banner-container {
    margin-top: 40px;
  }
}

@media (max-width: 380px) {
  .banner-container {
    margin-top: 30px;
  }
}
</style>