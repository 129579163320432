<template>
    <div class="table-container">
        <div class="table-title-container">
            <span>Otras opciones de préstamos <br> por tu auto como aval</span>
        </div>
        <div class="table-options-container">
            <div class="options-container">
                <p class="option">
                    <span class="text-red">✘</span> Entregas tu vehículo físicamente
                </p>
                <p class="option">
                    <span class="text-red">✘</span> Desplazamiento para presentar la documentación y el coche
                </p>
                <p class="option">
                    <span class="text-red">✘</span> Tardan varios días en aceptar el préstamo
                </p>
                <p class="option">
                    <span class="text-red">✘</span> Sí hay pago final
                </p>
                <p class="option">
                    <span class="text-red">✘</span> No abonas a capital
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TablaOpciones",
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.table-container {
    border-radius: 0.6rem;
    border: 2px solid #D8D8D8;
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-basis: 100%;

    .table-title-container {

        background-color: #D8D8D8;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem;
        padding: 1rem;


        span {
            color: #004680;
            text-align: center;
            font-family: 'Montserrat-Bold';
            font-size: 1rem;
            font-style: normal;
            line-height: normal;
        }

    }

    .options-container {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;

        .option {
            display: flex;
            align-items: center;
            color: #004680 !important;
            margin: 0rem 1.1rem;
            font-weight: 700;

            .text-red {
                color: #FA0000FE;
                margin-right: 1rem;
                font-size: 1.5rem;
            }

        }

    }
}

@include media-breakpoint-up(lg) {

    .table-container{
    }

  }
</style>