<template>
    <div class="body-container">
        <BannerSection
                       title="Empeños de coches: cómo funcionan, <br class='d-none d-lg-block'/>ventajas y cómo hacerlo" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <h1 class="bold-text"
                        style="font-size: 25px;">Empeños de coches: todo lo que debes saber</h1>
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/llaves.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <p class="mb-0">
                        ¿Necesitas dinero urgente? El empeño de coches puede ser una alternativa al empeño tradicional.
                        Te ofrece liquidez financiera casi inmediata, sin perder la posesión de tu vehículo.
                    </p>
                    <br>
                    <p>Este método permite acceder a préstamos utilizando el auto como aval, garantizando así la
                        disponibilidad del dinero que necesitas con rapidez y seguridad.
                    </p>
                    <p></p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funcionan los empeños de coches?
                        </h2>
                    </span>

                    <p>
                        Por lo general, la modalidad convencional de empeño significa entregar temporalmente el bien
                        como garantía del financiamiento. En el empeño de coches, por tanto, implica entregar tu coche
                        como garantía.
                    </p>
                    <p>Sin embargo, hay opciones como Ibancar que ofrecen una alternativa a la modalidad tradicional de
                        empeño. En este caso, no tendrás que entregar tu auto; simplemente, lo utilizas como respaldo.
                    </p>
                    <p>La empresa prestamista evalúa el valor de tu coche y te ofrece una cantidad de dinero acorde.</p>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Ventajas de empeñar tu coche sin entregarlo</h2>
                    </span>
                    <p>Empeñar tu coche ofrece múltiples ventajas. Entre ellas: </p>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Acceso rápido a dinero:
                            </p>
                            <span>puedes obtener fondos en menos de 48 horas.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Sin cambio de titularidad: 
                            </p>
                            <span>no pierdes la propiedad de tu auto y puedes seguir manejando sin restricciones.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Flexibilidad en pagos:
                            </p>
                            <span> los planes de pago se ajustan a tus necesidades. Además, puedes aportar capital desde
                                la primera mensualidad, sin penalización por pagos anticipados.</span>
                        </li>

                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Requisitos para empeño de coches</h2>
                        
                    </span>
                    <p>Los requisitos son accesibles para la mayoría de los propietarios de vehículos:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">Debes ser el propietario y titular del auto.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tener la factura original del vehículo.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Debe estar en buen estado y con todos los documentos en regla.
                                </span>

                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <h2 class="bold-text"
                        style="font-size: 20px;">¿Qué documentación necesitas para empeñar tu coche?</h2>
                    
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/hombreTelefono.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">INE escaneada por ambos lados y una selfie con el documento
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Comprobante de ingresos. Puedes entregar el último recibo de nómina o
                                    estado de cuenta.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Comprobante de domicilio con vigencia máxima de tres meses.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tarjeta de circulación.
                                </span>

                            </p>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0">
                                <span class="">Factura original del auto escaneada
                                </span>

                            </p>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0">
                                <span class="">Fotografías del coche.
                                </span>

                            </p>
                        </li>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Proceso rápido y seguro para el empeño de coches
                        </h2>
                        
                    </span>

                    <p>
                        El proceso de empeño es rápido y seguro, lo que garantiza que recibas tu dinero en menos de 48
                        horas tras la aprobación. Para realizarlo, solo debes:
                    </p>
                    <br>
                    <ol>
                        <li>
                            <span>Enviar tu solicitud en línea mediante el formulario web. Donde recibirás un préstamo
                                preaprobado al instante. </span>
                        </li>

                        <br>

                        <li>
                            <span>Aportar la documentación necesaria para tramitar tu solicitud. Una vez verificada,
                                nuestro equipo agendará una visita para revisar el vehículo. </span>
                        </li>
                        <br>

                        <li>
                            <span>Una vez recogida la factura y firmado el contrato, recibirás el dinero lo antes
                                posible en la cuenta que desees. </span>
                        </li>
                        <br>
                    </ol>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Preguntas frecuentes</h2>
                       
                    </span>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Puedo seguir utilizando mi auto durante el
                            empeño? </h3>
                        <p style="font-size: 17px;">Sí, una de las ventajas de empeñar tu coche es que puedes seguir
                            utilizándolo como siempre. A pesar de que el vehículo sirve como garantía
                            para el
                            préstamo, no necesitas entregarlo físicamente, ni se requiere cambio de titularidad. </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Necesito asistir a una sucursal para
                            completar el empeño?</h3>
                        <p style="font-size: 17px;">No, todo el proceso se realiza 100% en línea, lo que te permite
                            gestionar el empeño desde cualquier lugar sin necesidad de visitar una sucursal física.
                            Pero, si lo deseas, puedes visitar nuestra oficina para aclarar cualquier duda. </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Necesito un aval adicional aparte de mi
                            auto?</h3>
                        <p style="font-size: 17px;">No, tu coche es suficiente como aval. No se requieren avales
                            adicionales.</p>
                    </div>
                    <br>

                    <h2 style="font-size: 20px;"
                        class="bold-text">Contacta con nosotros para empeñar tu coche</h2>
                    <br>
                    <p>Si estás interesado en obtener más información o deseas solicitar un préstamo, no dudes en
                        contactarnos. </p>
                    <p>En <a href="https://ibancar.mx/contacto">Ibancar</a> ofrecemos un servicio amigable y rápido, garantizando tu satisfacción. Recuerda que no
                        cambiamos la titularidad de tu coche y que además puedes seguir utilizándolo normalmente
                        mientras pagas el préstamo.</p>

                    <p>Visita nuestra página web o llámanos directamente para comenzar tu solicitud. </p>
                    <p>Empeña tu coche con nosotros y soluciona tus necesidades financieras hoy mismo.</p>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'EmpenosDeCarrosLoQuedebesSaber',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Empeños de carros | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Empeña tu auto y recibe el dinero que necesitas en cuestión de horas. Tasación justa, trámites sencillos y tu coche sigue siendo tuyo. ¡Solicita ya! '
                    },
                    {
                        name: 'keywords',
                        content: 'empeños de carros'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/empenos-carros-lo-que-debes-saber', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>