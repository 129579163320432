<template>
    <div class="body-container">
        <BannerSection title="Consigue tu crédito en México con <br class='d-none d-lg-block'/> garantía prendaria" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        Cuando buscas opciones de financiamiento que realmente se ajusten a tus necesidades y que al
                        mismo tiempo sean claras y justas, el camino puede parecer complicado. En Ibancar, estamos
                        comprometidos contigo, ofreciéndote un <b>crédito con garantía prendaria</b> diseñado no solo
                        para
                        satisfacer tus requisitos financieros, sino para garantizarte seguridad y claridad absoluta en
                        cada paso del proceso.

                    </p>
                    <br>
                    <p>
                        Además, en Ibancar aceptamos personas en buró de crédito, siendo la mejor opción cuando no
                        puedes acceder a medios tradicionales. Descubre cómo funciona, los beneficios exclusivos que
                        ofrece y el sencillo proceso para solicitarlo.
                    </p>

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funciona el crédito con garantía prendaria?</h2>
                    </span>

                    <p class="mb-0">
                        El <b>crédito con garantía prendaria</b> que ofrecemos en Ibancar utiliza tu vehículo como aval para el préstamo, pero con la ventaja adicional de que lo puedes seguir usando, mientras vas pagando el préstamo, pero con la ventaja adicional de que lo puedes seguir usando,<b> sin limitaciones</b>,
                        mientras vas pagando el préstamo. Este esquema de financiamiento es perfecto si lo que buscas es
                        liquidez inmediata sin tener que dejar de contar con tus bienes más valiosos.
                    </p>

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Beneficios de elegir nuestro crédito prendario</h2>
                    </span>
                    <p>
                        Optar por Ibancar para tu <b>crédito con garantía prendaria</b> viene cargado de ventajas que no
                        encontrarás en otro lado:
                    </p>
                    <ol>
                        <li>
                            <p class="mb-0 bold-text">
                                Claridad desde el inicio:
                            </p>
                            <span>Olvídate de las letras chiquitas y las sorpresas en los contratos. Con Ibancar, cada
                                detalle del préstamo se explica claramente desde el principio.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Tasas que te convienen:
                            </p>
                            <span>Al tener tu auto como garantía, podemos ofrecerte tasas de interés más bajas que las
                                de los préstamos personales sin aval, resultando en pagos más ligeros para ti.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Flexibilidad real:
                            </p>
                            <span>Nos ajustamos a tu situación financiera para que el pago del préstamo no se convierta
                                en un peso para ti. Ofreciendo hasta 5 días de cortesía al mes. </span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Rapidez en el proceso:
                            </p>
                            <span>Sabemos que cuando necesitas financiamiento, lo necesitas ya. Por eso hacemos todo lo
                                posible por agilizar tu solicitud para que tengas tu dinero cuanto antes.</span>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0 bold-text">
                                El proceso de solicitud es totalmente online:
                            </p>
                            <span>también pueden asistir a nuestras oficinas para solventar cualquier duda. </span>
                        </li>

                    </ol>

                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Pasos para solicitar tu préstamo con garantía en México</h2>
                    </span>
                    <p>Pedir un <b>crédito con garantía prendaria</b> en Ibancar te es muy sencillo y lo puedes hacer
                        sin
                        salir de casa:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Paso 1:
                                </span>Sólo tienes que rellenar nuestro formulario en línea para una pre-evaluación
                                rápida de tu coche.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Paso 2:
                                </span>Reunir la siguiente documentación. Junta tu INE, un comprobante de domicilio
                                reciente y los papeles de tu vehículo (factura y tarjeta de circulación).

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Paso 3:
                                </span>Manda tu solicitud por nuestra plataforma para recibir un préstamo preaprobado al
                                instante. Nuestro equipo revisará tu caso y te contactará con una oferta pensada
                                especialmente para ti.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Paso 4:
                                </span> Se comprobará la documentación aportada. Una vez se acepte, se agendará una
                                visita donde un profesional comprobará el vehículo y recogerá la factura.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Paso 5:
                                </span>Una vez firmado el contrato y recogida la factura, recibirás el dinero en tu
                                cuenta tan rápido como nos sea posible. Además, seguirás usando tu auto durante el
                                tiempo que dure el préstamo.

                            </p>
                        </li>
                    </ul>
                    <br>
                    <p>En Ibancar, nuestro compromiso es darte opciones financieras que realmente te ayuden a avanzar
                        hacia tus objetivos sin ataduras. Nuestro <b>crédito con garantía prendaria</b> es muestra de nuestro
                        esfuerzo por ofrecerte soluciones justas, claras y que realmente se ajusten a lo que los
                        mexicanos necesitan.</p>
                    <p>Visita nuestra web para más información y empieza tu camino hacia la libertad financiera sin
                        tener que renunciar a lo que más importa para ti.</p>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'ConsigueTuCreditoConGarantiaPrendaria',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamo por tu bien en México: Crédito con garantía prendaria | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Obtén financiamiento inmediato en México dejando tu bien como garantía prendaria. Proceso seguro y confiable. Solicita ahora y consigue liquidez sin complicaciones.'
                    },
                    {
                        name: 'keywords',
                        content: 'credito con garantia prendaria'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/consigue-tu-credito-con-garantia-prendaria', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>